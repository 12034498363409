import React from 'react';
import { Menu, Icon } from 'semantic-ui-react';
import ColoredCheckbox from './ColoredCheckbox';

import '../assets/css/ui/Switch.module.scss';

export default (props) => {
  const { options, checked, onChange, widths, name, type, checkboxStyle, color, cssTags, containerStyle, vertical } = props;
  let blocks = [options];

  if (widths) {
    blocks = options.reduce((prev, item, i) => {
      const where = Math.floor(i / widths);
      const isNew = i === 0 || i % widths === 0;
      if (isNew) {
        prev.push([item]);
      } else {
        prev[where].push(item);
      }
      return prev;
    }, []);

    if (blocks.length && blocks[blocks.length - 1].length < widths) {
      const blocksLen = blocks.length;
      const blockLen = blocks[blocksLen - 1].length;

      blocks[blocksLen - 1] = blocks[blocksLen - 1].concat(Array(widths - blockLen).fill().map(() => null));
    }
  }

  return (
    <div style={containerStyle}>
      {
        blocks.map((block, i) =>
          <Menu
            key={i}
            name={props.name}
            fluid
            widths={widths || block.length}
            vertical={vertical}
          >
            {
              block.map((option, j) => {
                if (!option) return <Menu.Item key={j} />;
                return (
                  type === 'entity' ?
                    <Menu.Item
                      className={`${type} ${cssTags}`}
                      style={checked === option.value ? { paddingTop: '13px' } : {}}
                      data-testid={option.testId}
                      data-action="toggle-switch"
                      key={j}
                      value={option.value}
                      active={checked === option.value}
                      onClick={onChange}
                      name={name}
                    >
                      <Icon name={option.icon} style={{ margin: 0 }} />
                      <span>{option.text}</span>
                    </Menu.Item>
                    :
                    type === 'integration' ?
                      <Menu.Item
                        className={`${type} ${cssTags}`}
                        style={checked === option.value ? { paddingTop: '13px' } : {}}
                        data-testid={option.testId}
                        data-action="toggle-switch"
                        key={j}
                        value={option.value}
                        active={checked === option.value}
                        onClick={onChange}
                        name={name}
                      >
                        <span>{option.text}</span>
                      </Menu.Item>
                      :
                      type === 'status' ?
                        <Menu.Item
                          className={type}
                          style={checked === option.value ? { paddingTop: '13px', ...option.style } : { ...option.style }}
                          data-testid={option.testId}
                          data-action="toggle-switch"
                          key={j}
                          value={option.value}
                          active={checked === option.value}
                          onClick={onChange}
                          name={name}
                        >
                          <ColoredCheckbox color={color} radio bold={false} checked={checked === option.value} label={option.text} style={checkboxStyle} />
                        </Menu.Item>
                        :
                        <Menu.Item
                          className={type}
                          data-testid={option.testId}
                          data-action="toggle-switch"
                          key={j}
                          value={option.value}
                          active={checked === option.value}
                          onClick={onChange}
                          name={name}
                        >
                          <ColoredCheckbox color={color} radio bold={false} checked={checked === option.value} label={option.text} style={checkboxStyle} />
                        </Menu.Item>
                );
              })
            }
          </Menu>)
      }
    </div>
  );
};
