import React from 'react';

import { fireEvent, render, screen, waitFor } from '../../../../../../newRender';
import { Form } from '.';

import { __ } from '../../../../../../i18n';
import { DefaultAutomaticMessages } from '../AutomaticMessages/constants/default-automatic-messages';
import * as CanSeeAutomaticMessagesUseCase from '../../../../../Domain/UseCases/AutomaticMessage/CanSeeAutomaticMessagesUseCase';
import * as helperHandlerMessageScheduled from '../../../../../../helpers/handleCheckEntitiesHasMessagesScheduled';

describe('<Form />', () => {
  const props = {
    currentEntityId: 1,
    isLoading: false,
    isDisabled: false,
    onBack: jest.fn,
    hasMessageApprove: true,
    entity: {
      id: 1,
      fullname: 'Mr Holmes',
    },
    onSubmit: jest.fn,
    canAddPicture: true,
  };

  beforeEach(() => {
    jest.clearAllMocks();
    jest
      .spyOn(CanSeeAutomaticMessagesUseCase, 'canSeeAutomaticMessagesUseCase')
      .mockReturnValue(true);
  });

  it('should to be in the document', () => {
    const { getByTestId } = render(<Form {...props} />);

    const form = getByTestId('ChannelForm');

    expect(form).toBeInTheDocument();
  });

  it('should to be channel input in the document', () => {
    const { getByPlaceholderText, getByText } = render(<Form {...props} />);

    const input = getByPlaceholderText(__('Ex: Secretary'));
    const label = getByText(`${__('Channel`s name')} *`);

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should to be external id input in the document', () => {
    const { getByPlaceholderText, getByText } = render(<Form {...props} />);

    const input = getByPlaceholderText(__('Optional'));
    const label = getByText(`${__('External ID')}`);

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should to be description input in the document', () => {
    const { getByPlaceholderText, getByText } = render(<Form {...props} />);

    const input = getByPlaceholderText(
      __('Write a description, it`s optional'),
    );
    const label = getByText(`${__('Description')}`);

    expect(input).toBeInTheDocument();
    expect(label).toBeInTheDocument();
  });

  it('should to be office hours input in the document', () => {
    const { getByTestId, getByText } = render(<Form {...props} />);

    const officeHoursText = getByText(__('Office Hours'));
    const openTimeDropdown = getByTestId('open-time-dropdown');
    const closeTimeDropdown = getByTestId('close-time-dropdown');

    expect(officeHoursText).toBeInTheDocument();
    expect(openTimeDropdown).toBeInTheDocument();
    expect(closeTimeDropdown).toBeInTheDocument();
  });

  it('should to be picture input in the document', () => {
    const { getByText } = render(<Form {...props} />);

    const title = getByText(__('Add a picture or choose one bolow:'));
    expect(title).toBeInTheDocument();
  });

  it('should to be select users input in the document', () => {
    const { getByText } = render(<Form {...props} />);

    const title = getByText(`${__('Responsible')} *`);
    expect(title).toBeInTheDocument();
  });

  it('should to be select visibility input in the document', () => {
    const { getByText } = render(<Form {...props} />);

    const title = getByText(`${__('Visibility')} *`);
    expect(title).toBeInTheDocument();
  });

  it('should to be manage permissions input in the document', () => {
    const { getByText } = render(<Form {...props} />);

    const title = getByText(__('Manage Permissions'));
    expect(title).toBeInTheDocument();
  });

  it('should be able to change description', () => {
    const { getByPlaceholderText } = render(<Form {...props} />);

    const input = getByPlaceholderText(
      __('Write a description, it`s optional'),
    );

    expect(input).toBeInTheDocument();

    fireEvent.change(input, { target: { value: 'Hello' } });

    expect(input.value).toBe('Hello');
  });

  it('should be able to render the automaticMessages with entity data', () => {
    props.entity.automaticMessages = {
      nodes: DefaultAutomaticMessages(),
    };

    const { getByText } = render(<Form {...props} hasMenuConversations />);

    const title = getByText(__('Outside service hours'));

    expect(title).toBeVisible();
  });

  it('should be able to change the channel type to attendance without status and see the Outsie service hours', async () => {
    props.entity.automaticMessages = {
      nodes: DefaultAutomaticMessages(),
    };
    const { getByText } = render(
      <Form {...props} hasMenuConversations={true} />,
    );

    const simpleDirect = getByText('Simple conversation, no attendance status');

    fireEvent.click(simpleDirect);

    const title = getByText(__('Outside service hours'));

    expect(title).toBeVisible();

    const withAttendanceStatus = getByText('With attendance status');

    fireEvent.click(withAttendanceStatus);

    const firstMessageTrigger = getByText(__('After first message'));

    expect(firstMessageTrigger).toBeVisible();
  });


  it('should view show Alert modal after click in disable button', async () => {
    jest.spyOn(helperHandlerMessageScheduled, 'handleCheckEntitiesHasMessagesScheduled').mockResolvedValue(['Mr Holmes']);

    render(
      <Form {...props} hasMenuConversations={true} isEditable={true} />,
    );

    const disableButton = screen.getAllByTestId('without-status-option');
    fireEvent.click(disableButton[0]);

    waitFor(() => {
      expect(screen.getByTestId('alert-entity-has-message-scheduled')).toBeVisible();
    });
  });
});
