
import React from "react";
import { differenceWith, isEqual } from "lodash";

import { __ } from "../i18n";
import Loading from "./ui/Loading";

import { handleCheckEntitiesHasMessagesScheduled } from '../helpers/handleCheckEntitiesHasMessagesScheduled';
import Mixpanel, { TRACK_EVENT_NAME } from '../lib/mixpanel';
import { TRACK_EVENT_SCOPE } from '../lib/mixpanel/scopes';
import { TRACK_EVENT_IDENTIFIER } from '../lib/mixpanel/identifiers';
import { TRACK_EVENT_PAGE_NAME } from '../lib/mixpanel/pages';

export default class AlertEntitiesWithMessageScheduled extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      namesOfEntitiesWithScheduledMessage: [],
    }
  }

  componentDidMount() {
    this.getNameOfEntitiesWithCase();
  }

  componentDidUpdate(prevProps) {
    const difference = !isEqual(prevProps.entities, this.props.entities);
    if(difference && !this.state.loading) {
      this.getNameOfEntitiesWithCase();
    }
  }

  getNameOfEntitiesWithCase = async () => {
    const { entities, store} = this.props;
    this.setState({ loading: true }, async () => {
      const namesOfEntitiesWithScheduledMessage = await handleCheckEntitiesHasMessagesScheduled(entities, store);
      this.setState({
        loading: false,
        namesOfEntitiesWithScheduledMessage,
      });
    })
  }

  trackModalView = () => {
    const { pageName } = this.props;
    const { namesOfEntitiesWithScheduledMessage } = this.state;
    if(pageName && namesOfEntitiesWithScheduledMessage.length > 0) Mixpanel.sendEvent({
      name: TRACK_EVENT_NAME.MODAL_VIEWED,
      scope: TRACK_EVENT_SCOPE.ENTITY_CONFIGURATION,
      pageName,
      identifierName: TRACK_EVENT_IDENTIFIER.DISABLE_ENTITY,
      customProperties: {
        modal_name: 'confirmar_desativar_entidades_com_mensagens_agendadas',
        number_of_entities: namesOfEntitiesWithScheduledMessage.length,
      }
    });
  }

  render() {
    const { pageName } = this.props;
    const { loading, namesOfEntitiesWithScheduledMessage } = this.state;

    if(loading) return <Loading width={30} height={30} />;

    this.trackModalView();
    
    return namesOfEntitiesWithScheduledMessage.length > 0 &&(
      <div>
        <p><b>{__('Attention')}!</b></p>
        <br></br>
        <p>{
          pageName == TRACK_EVENT_PAGE_NAME.CHANNEL_LIST ? 
          __('The following channels have messages scheduled. Disable these channels, and their messages will not be sent.') :
          __('The following people have messages scheduled. Disable this people, and their messages will not be sent.')
        }</p>
        <br></br>
        <p><b>{namesOfEntitiesWithScheduledMessage.join(', ')}</b></p>
      </div>
    )
  }
}